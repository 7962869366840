import cn from "classnames";

import Contacts from "../Contacts/Contacts";

const Service = ({ button }) => {
  return (
    <div className="service">
      <div className={cn("px", { button })}>SISKO Service Kft.</div>
      <div className="p">Autó és gumi gyorsszerviz!</div>
      <Contacts vertical />
    </div>
  );
};

export default Service;
