import "./ContactPage.css";
import Page from "../../components/Page/Page";
import Opening from "../../components/Opening/Opening";
import Map from "../../components/Map/Map";
import Service from "../../components/Service/Service";

const ContactPage = () => {
  return (
    <Page>
      <div className="contact-page">
        <h1 className="center">Kapcsolat</h1>
        <div className="contact-page__content">
          <Service button />
          <Opening button />
          <Map />
        </div>
      </div>
    </Page>
  );
};

export default ContactPage;
