import { BACKEND_URL } from "../config";
import { debug } from "./logHelpers";
import { getCookie } from "./cookieHelpers";

export const request = async (
  url,
  {
    method = "GET",
    body = null,
    bodyType = "json",
    query = null,
    credentials = "include",
    mode = "cors",
    headers = {},
    responseType = "json",
  } = {}
) => {
  const options = {
    method,
    headers,
    credentials,
    mode,
  };

  if (body) {
    if (bodyType === "json") {
      options.body = JSON.stringify(body);
    } else if (bodyType === "form-data") {
      const formData = new FormData();
      const keys = Object.keys(body);
      keys.forEach((key) => {
        formData.append(key, body[key]);
      });
      options.body = formData;
    } else {
      options.body = body;
    }
  }

  const uri =
    url +
    (query
      ? (url.indexOf("?") === -1 ? "?" : "&") +
        Object.keys(query)
          .map((key) => key + "=" + query[key])
          .join("&")
      : "");

  try {
    const response = await fetch(uri, options);
    const headers = {};

    response.headers.forEach((header, index) => {
      headers[index] = header;
    });

    const result = {
      status: response.status,
      statusText: response.statusText,
      ok: response.ok,
      headers,
    };
    if (response?.[responseType]) {
      try {
        result[responseType] = await response[responseType]();
      } catch (e) {}
    }

    return result;
  } catch (e) {
    return {
      status: 0,
      statusText: "",
      ok: false,
      exception: e,
    };
  }
};

export const apiCall = async (route, options = {}) => {
  options.headers = options?.headers || {};
  options.headers["X-CSRFToken"] = getCookie("csrftoken");

  try {
    const response = await request(`${BACKEND_URL}${route}`, options);
    if (response.ok && response.json) {
      debug("apiCall.ok", route, options.method || "GET", options, response.json);
      return response.json;
    } else {
      debug("apiCall.error", route, options.method || "GET", options, response);
    }
  } catch (e) {
    debug("apiCall.exception", route, options.method || "GET", options, e);
  }
  return null;
};
