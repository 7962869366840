import "./Hamburger.css";

const Hamburger = ({ open, onClick }) => {
  return (
    <div className={`hamburger${open ? " open" : ""}`} onClick={onClick}>
      <div className="bar1" />
      <div className="bar2" />
      <div className="bar3" />
    </div>
  );
};

export default Hamburger;
