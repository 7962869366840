import { useState } from "react";

import "./Header.css";
import Logo from "../Logo/Logo";
import Contacts from "../Contacts/Contacts";
import Menu from "../Menu/Menu";
import Hamburger from "../Hamburger/Hamburger";
import Overlay from "../Overlay/Overlay";

const Header = ({ refs }) => {
  const [open, setOpen] = useState(false);

  const toggleOverlay = () => {
    setOpen(!open);
  };

  const closeOverlay = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="header">
        <header>
          <Logo />
          <Contacts small />
          <Menu refs={refs} />
          <Hamburger open={open} onClick={toggleOverlay} />
        </header>
      </div>
      <Overlay open={open} onClick={closeOverlay} refs={refs} />
    </>
  );
};

export default Header;
