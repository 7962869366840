import { useCallback, useEffect, useRef, useState } from "react";

import "./Home.css";
import Page from "../../components/Page/Page";
import { apiCall } from "../../helpers/requestHelpers";

const Home = () => {
  const refs = [
    { ref: useRef(), id: "autoszerviz" },
    { ref: useRef(), id: "gumiszerviz" },
    { ref: useRef(), id: "gumihotel" },
  ];

  const [banners, setBanners] = useState([]);

  const load = useCallback(async () => {
    setBanners((await apiCall("sisko/banners/")) || []);
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Page refs={refs}>
      <div className="home__block home_block">
        <div className="home__action">
          <div className="home__action__inner">
            <p className="uppercase bold font-size-72 orange">
              Márciusi akció!
            </p>
            <p className="uppercase font-size-24">
              Szereltesse fel nyári kerekeit vagy gumijait szervizünkben
            </p>
            <p className="uppercase bold font-size-48">
              Teljes <span className="orange">gumiszerviz</span>
            </p>
            <p className="uppercase bold font-size-48">
              Ingyen <span className="orange">gumihotel!</span>
            </p>
          </div>
        </div>
      </div>

      {banners.map((banner) => (
        <div className="home__block home_block">
          <div className="home__banner">
            <img src={banner.image} alt="" />
          </div>
        </div>
      ))}

      <div
        className="home__service__outer home_block"
        id={refs[0].id}
        ref={refs[0].ref}
      >
        <div className="home__service">
          <div className="home__service__inner">
            <div className="home__service__inner__text">
              <p className="uppercase bold font-size-72">
                Autó<span className="orange">szerviz</span>
              </p>
              <p className="uppercase font-size-24">
                2020 áprilisában indult el az autószervíz. Személy és
                kisteherautók teljeskörű szervizelését, javítását,
                karbantartását márkától és típustól függetlenül! Izzócserétől a
                motorgenerálig mindent. Amennyiben javítást szükséges végezni a
                gépjárműn, autószervizünkben kedvező árajánlatot készítünk, mely
                magában foglalja a munkadíjat és az alkatrész díját is.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="home__block-3__outer home_block"
        id={refs[1].id}
        ref={refs[1].ref}
      >
        <div className="home__block-3">
          <div className="home__block-3__inner">
            <div className="home__block-3__inner__text">
              <p className="uppercase bold font-size-72">
                Gumi<span className="orange">szerviz</span>
              </p>
              <p className="uppercase font-size-24">
                Cégünk új gumiabroncsok értékesítésével, szerelésével és
                javításával foglalkozik. Segédberendezéseknek köszönhetően
                biztonságosan, sérülésmentesen szerelhetünk merev, illetve
                alacsony oldalfalú abroncsokat is. Szerelés közben mindig
                használunk a keréktárcsa és a gumiabroncs peremének védelmére
                szolgáló kiegészítőket. Mind a téli, mind a nyári gumicsere
                alkalmával ügyfeleink tájékoztatást kapnak a kerekek
                állapotáról. Szezonális cserénél szervizünk komplett megoldást
                kínál: felszerelés, centrírozás, nyomásellenőrzés, profilmélység
                ellenőrzés korszerű gépekkel.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="home__block-4__outer home_block"
        id={refs[2].id}
        ref={refs[2].ref}
      >
        <div className="home__block-4">
          <div className="home__block-4__inner">
            <div className="home__block-4__inner__text">
              <p className="uppercase bold font-size-72">
                Gumi<span className="orange">hotel</span>
              </p>
              <p className="uppercase font-size-24">
                Amikor nincs úton, ne legyen útban! A gumi tárolás sokszor
                helyszűke miatt nehézséges, ebben segítünk mi gumi hotel
                szolgáltatásunk keretein belül! Nyáron téli gumi, télen nyári
                gumi garnitúráját nem kell hazavinnie, mi tároljuk Ön helyett.
                Önnek csak telefonálnia kell, ha jönne felszereltetni. Mire
                megérkezik szervizünkbe, addigra előkészítjük, ezzel is
                csökkentve az esetleges várakozás idejét.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Home;
