import { useState } from "react";
import "./Request.css";
import Page from "../../components/Page/Page";

const Request = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [type, setType] = useState("");
  const [make, setMake] = useState("");
  const [engine, setEngine] = useState("");
  const [number, setNumber] = useState("");
  const [year, setYear] = useState("");
  const [plate, setPlate] = useState("");
  const [description, setDescription] = useState("");
  const [checkboxPhone, setCheckboxPhone] = useState(false);
  const [checkboxEmail, setCheckboxEmail] = useState(false);
  const [checkboxPrivacy, setCheckboxPrivacy] = useState(false);
  const [errors, setErrors] = useState([]);

  const formSubmit = (e) => {
    let newError = [];

    if (!phone && !email) {
      newError.push("Adja meg telefonszámát vagy email címét!");
    }
    if (!brand) {
      newError.push("Adja meg az autó márkáját!");
    }
    if (!model) {
      newError.push("Adja meg az autó modelljét!");
    }
    if (!make) {
      newError.push("Adja meg az autó kivitelét!");
    }
    if (!year) {
      newError.push("Adja meg az autó évjáratát!");
    }
    if (!engine) {
      newError.push("Adja meg az autó motorját!");
    }
    if (!plate) {
      newError.push("Adja meg az autó rendszámát!");
    }
    if (!description) {
      newError.push("Adja meg a probléma leírását!");
    }
    if (!checkboxPrivacy) {
      newError.push("Fogadja el az adatvédelmi nyilatkozatot!");
    }

    if (newError.length > 0) {
      setErrors(newError);
      e.preventDefault();
    } else {
      setErrors([]);
    }
  };

  return (
    <Page>
      <div className="request">
        <h1 className="center">Ajánlatkérés</h1>
        <p className="uppercase bold font-size-24">
          Kérje ingyenes árajánlatunkat
        </p>
        <p>
          Töltse ki az alábbi űrlapot és autószervizes munkatársunk felveszi
          Önnel a kapcsolatot.
        </p>
        <form
          action="https://formsubmit.co/76d7184ce56a724ed075ac5876672fcd"
          method="POST"
          encType="multipart/form-data"
          onSubmit={formSubmit}
        >
          <div className="request__title">Személyes adatok:</div>
          <div className="request__form request__form_personal">
            <label>
              <span>Név</span>
              <input
                type="text"
                name="Név"
                placeholder=""
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
            <label>
              <span>Telefonszám **</span>
              <input
                type="text"
                name="Telefonszám"
                placeholder="+36123456789"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
            <label>
              <span>E-mail cím **</span>
              <input
                type="text"
                name="Email"
                placeholder="email@domain.hu"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
          </div>

          <div className="request__title">Autó adatai:</div>
          <div className="request__form request__form_car">
            <label>
              <span>Márka *</span>
              <input
                type="text"
                name="Márka"
                placeholder="Suzuki"
                value={brand}
                onChange={(e) => {
                  setBrand(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
            <label>
              <span>Modell *</span>
              <input
                type="text"
                name="Modell"
                placeholder="Swift"
                value={model}
                onChange={(e) => {
                  setModel(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
            <label>
              <span>Típus</span>
              <input
                type="text"
                name="Típus"
                placeholder="GLX"
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
            <label>
              <span>Kivitel *</span>
              <input
                type="text"
                name="Kivitel"
                placeholder="Ferdehátú"
                value={make}
                onChange={(e) => {
                  setMake(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
            <label>
              <span>Évjárat *</span>
              <input
                type="text"
                name="Évjárat"
                placeholder="2010"
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
            <label>
              <span>Motor *</span>
              <input
                type="text"
                name="Motor"
                placeholder="1.2 benzin"
                value={engine}
                onChange={(e) => {
                  setEngine(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
            <label>
              <span>Rendszám *</span>
              <input
                type="text"
                name="Rendszám"
                placeholder="ABC123"
                value={plate}
                onChange={(e) => {
                  setPlate(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
            <label>
              <span>Alvázszám</span>
              <input
                type="text"
                name="Alvázszám"
                placeholder=""
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
          </div>

          <div className="request__title">Probléma:</div>
          <div className="request__form request__form_problem">
            <label>
              <span>Probléma rövid leírása *</span>
              <textarea
                name="Probléma"
                placeholder=""
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setErrors([]);
                }}
              />
            </label>
          </div>

          <div className="request__title">
            Dokumentum(ok) csatolása (forgalmi):
          </div>
          <div className="request__form request__form_attachment">
            <div>
              <input
                type="file"
                name="Csatolmány1"
                onChange={(e) => {
                  setErrors([]);
                }}
              />
            </div>
            <div>
              <input
                type="file"
                name="Csatolmány2"
                onChange={(e) => {
                  setErrors([]);
                }}
              />
            </div>
            <div>
              <input
                type="file"
                name="Csatolmány3"
                onChange={(e) => {
                  setErrors([]);
                }}
              />
            </div>
          </div>

          <div className="request__form request__form_submit">
            <div className="orange">
              <label className="block">
                <input
                  type="checkbox"
                  name="Telefonon"
                  checked={checkboxPhone}
                  onChange={(e) => {
                    setCheckboxPhone(e.target.checked);
                    setErrors([]);
                  }}
                />{" "}
                Telefonon kérek vissza jelzést
              </label>
              <label className="block">
                <input
                  type="checkbox"
                  name="Emailben"
                  checked={checkboxEmail}
                  onChange={(e) => {
                    setCheckboxEmail(e.target.checked);
                    setErrors([]);
                  }}
                />{" "}
                E-mail-ben kérek vissza jelzést
              </label>
              <label className="block">
                <input
                  type="checkbox"
                  name="Adatvédelmi"
                  checked={checkboxPrivacy}
                  onChange={(e) => {
                    setCheckboxPrivacy(e.target.checked);
                    setErrors([]);
                  }}
                />{" "}
                Elfogadom az adatvédelmi nyilatkozatot
              </label>
            </div>
            <div>
              <input type="submit" value="Küldés" />
            </div>
          </div>

          <div className="request__form_error">
            {errors.map((error) => (
              <div>{error}</div>
            ))}
          </div>
        </form>
      </div>
    </Page>
  );
};

export default Request;
