import { NODE_ENV } from "../config";
import { getQueryParam } from "./locationHelpers";

export const debug = (...messages) => {
  if (NODE_ENV === "production") {
    const level = messages?.[0]?.split?.(".").pop();
    if (["error", "exception"].includes(level) || getQueryParam("debug")) {
      console.debug(...messages);
    }
  } else {
    console.debug(...messages);
  }
};
