import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "../../../node_modules/normalize.css/normalize.css";
import "./App.css";
import Home from "../../pages/Home/Home";
import ContactPage from "../../pages/ContactPage/ContactPage";
import Request from "../../pages/Request/Request";
import Gallery from "../../pages/Gallery/Gallery";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/ajanlatkeres" component={Request} />
        <Route path="/galeria" component={Gallery} />
        <Route path="/kapcsolat" component={ContactPage} />
      </Switch>
    </Router>
  );
}

export default App;
