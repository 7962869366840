import { Link } from "react-router-dom";

import "./Logo.css";
import logoIcon from "./assets/logo-icon.png";
import logoTitle from "./assets/logo-title.png";

const Logo = ({ withTitle = true }) => {
  return (
    <Link to="/" className="logo">
      <div>
        <img src={logoIcon} alt="logo-ikon" />
      </div>
      {withTitle && (
        <div>
          <img src={logoTitle} alt="logo" />
        </div>
      )}
    </Link>
  );
};

export default Logo;
