import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Page = ({ children, refs }) => {
  return (
    <>
      <Header refs={refs} />
      {children}
      <Footer refs={refs} />
    </>
  );
};

export default Page;
