import "./Contact.css";
import Icon from "../Icon/Icon";

const Contact = ({ children, name, alt, href, target = "_self", rel = "" }) => {
  return (
    <div className="contact">
      {href ? (
        <a className="contact__content" href={href} target={target} rel={rel}>
          <Icon name={name} alt={alt} />
          {children && <div>{children}</div>}
        </a>
      ) : (
        <div>
          <Icon name={name} alt={alt} />
          {children && <div>{children}</div>}
        </div>
      )}
    </div>
  );
};

export default Contact;
