import "./Opening.css";

const opening = [
  { day: "Hétfő", opening: "8:00 - 16:00" },
  { day: "Kedd", opening: "8:00 - 16:00" },
  { day: "Szerda", opening: "8:00 - 16:00" },
  { day: "Csütörtök", opening: "8:00 - 16:00" },
  { day: "Péntek", opening: "8:00 - 16:00" },
  { day: "Szombat", opening: "zárva" },
  { day: "Vasárnap", opening: "zárva" },
];

const Opening = ({ button }) => {
  return (
    <div className="opening">
      <div className={button ? "button" : "title"}>Nyitvatartás</div>
      <div>
        <table>
          <tbody>
            {opening.map((item, index) => (
              <tr key={index}>
                <td>{item.day}:</td>
                <td className="right uppercase">{item.opening}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Opening;
