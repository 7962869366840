import "./Icon.css";

const Icon = ({ name, alt = "" }) => {
  return (
    <div className="icon">
      <img src={require(`./assets/${name}.png`).default} alt={alt} />
    </div>
  );
};

export default Icon;
