import { useCallback, useEffect, useState } from "react";

import "./Gallery.css";
import Page from "../../components/Page/Page";
import { apiCall } from "../../helpers/requestHelpers";

const Gallery = () => {
  const [pictures, setPictures] = useState([]);

  const load = useCallback(async () => {
    setPictures((await apiCall("sisko/pictures/")) || []);
  }, []);

  useEffect(() => {
    load();
  }, [load]);
  return (
    <Page>
      <div className="gallery">
        <h1 className="center">Galéria</h1>

        <div className="gallery__pictures">
          {pictures.map((banner) => (
            <img src={banner.image} alt="" />
          ))}
        </div>
      </div>
    </Page>
  );
};

export default Gallery;
