import cn from "classnames";

import "./Contacts.css";
import Contact from "../Contact/Contact";

const Contacts = ({ vertical, small }) => {
  return (
    <div
      className={cn("contacts", {
        contacts_vertical: vertical,
        contacts_small: small,
      })}
    >
      <Contact
        name="address"
        altn="ikon-cím"
        href="https://goo.gl/maps/NEMvq2NfxH2bNSYG8"
        target="_blank"
        rel="noreferrer"
      >
        Győr, Bútorgyári utca 4.
      </Contact>
      <Contact name="phone" alt="ikon-telefon" href="tel:+36202493897">
        20/249-3897
      </Contact>
      <Contact
        name="facebook"
        alt="ikon-facebook"
        href="https://www.facebook.com/SISKO.HU"
        target="_blank"
        rel="noreferrer"
      >
        SISKO.HU
      </Contact>
    </div>
  );
};

export default Contacts;
