import "./Footer.css";
import Menu from "../Menu/Menu";
import Opening from "../Opening/Opening";
import Map from "../Map/Map";
import Service from "../Service/Service";

const Footer = ({ refs }) => {
  return (
    <div className="footer">
      <footer>
        <div className="footer__contacts">
          <div className="title">Kapcsolat</div>
          <Service />
        </div>

        <div className="footer__opening">
          <Opening />
        </div>

        <div className="footer__sitemap">
          <div className="title">Oldaltérkép</div>
          <div>
            <Menu refs={refs} vertical />
          </div>
        </div>

        <div className="footer__map">
          <Map />
        </div>

        <div className="footer__copyright">
          Minden jog fenntartva &copy; {new Date().getFullYear()}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
