import "./Map.css";

const Map = () => {
  return (
    <div className="map">
      <iframe
        className="map__iframe"
        title="Sisko Autó-és gumiszerviz"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2685.9097428916402!2d17.656615915633324!3d47.68617277919004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476bbf4caa09082f%3A0x9ac88a26e3e0948e!2sSisko%20Aut%C3%B3-%C3%A9s%20gumiszerviz!5e0!3m2!1shu!2shu!4v1632406559182!5m2!1shu!2shu"
        width="800"
        height="600"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      />
    </div>
  );
};

export default Map;
