import "./Overlay.css";
import Menu from "../Menu/Menu";
import Contacts from "../Contacts/Contacts";

const Overlay = ({ open, onClick, refs }) => {
  return (
    <div className={`overlay${open ? " open" : ""}`}>
      <Menu onClick={onClick} refs={refs} />
      <Contacts vertical small />
    </div>
  );
};

export default Overlay;
