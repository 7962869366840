import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";

import "./Menu.css";

const menus = [
  {
    to: {
      pathname: "/",
      hash: "#autoszerviz",
    },
    exact: true,
    name: "Autószerviz",
  },
  {
    to: {
      pathname: "/",
      hash: "#gumiszerviz",
    },
    exact: true,
    name: "Gumiszerviz",
  },
  {
    to: {
      pathname: "/",
      hash: "#gumihotel",
    },
    exact: true,
    name: "Gumihotel",
  },
  {
    to: {
      pathname: "/ajanlatkeres",
      hash: "",
    },
    exact: false,
    name: "Ajánlatkérés",
  },
  {
    to: {
      pathname: "/galeria",
      hash: "",
    },
    exact: false,
    name: "Galéria",
  },
  {
    to: {
      pathname: "/kapcsolat",
      hash: "",
    },
    exact: false,
    name: "Kapcsolat",
  },
];

const Menu = ({ onClick, refs, vertical }) => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(null);

  const getActiveMenu = () => {
    let newActiveMenu = null;
    if (refs) {
      for (let i = 0; i < refs.length; i++) {
        if (
          refs[i].ref &&
          refs[i].ref.current &&
          refs[i].ref.current.getBoundingClientRect().top <= 94
        ) {
          newActiveMenu = "#" + refs[i].id;
        }
      }
    }
    setActiveMenu(newActiveMenu);
  };

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(getActiveMenu, [location]);

  const setScrollEventListener = () => {
    window.addEventListener("scroll", getActiveMenu, { passive: true });
    return () => {
      window.removeEventListener("scroll", getActiveMenu);
    };
  };

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(setScrollEventListener, []);

  const isActive = (blockIndex) => (match) => {
    if (!match) {
      return false;
    }
    return activeMenu === blockIndex;
  };

  const scrollToHash = (hash) => {
    if (refs) {
      for (let i = 0; i < refs.length; i++) {
        if (hash === "#" + refs[i].id && refs[i].ref && refs[i].ref.current) {
          window.scrollTo(0, refs[i].ref.current.offsetTop - 94);
          return true;
        }
      }
    }
    return false;
  };

  const scrollTo = () => {
    if (!scrollToHash(location.hash)) {
      window.scrollTo(0, 0);
    }
  };

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(scrollTo, [refs]);

  return (
    <nav className={cn("menu", { menu_vertical: vertical })}>
      {menus.map((menu, index) => (
        <NavLink
          key={index}
          to={menu.to}
          exact={menu.exact}
          onClick={onClick}
          isActive={menu.to.hash ? isActive(menu.to.hash) : null}
        >
          {menu.name}
        </NavLink>
      ))}
    </nav>
  );
};

export default Menu;
